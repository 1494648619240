const production: boolean = true;

export const environment = {
  production: production,
  logo: "/assets/images/logo.png",
  endpoint: "https://tcmarkets-api.tech4broker.in/client",
  logo_width: 200,
  inner_logo_width: 80,
  windows_platform:
    "https://metatraders.s3.eu-west-2.amazonaws.com/ActiveX.exe",
  android_platform:
    "https://metatraders.s3.eu-west-2.amazonaws.com/ActiveX.apk",
  ios_platform:
    "#",
  web: "https://activex.trademql5.com",
  source: "https://portal.tsmarketss.com"
};